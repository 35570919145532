import React, { FunctionComponent } from "react"
import styles from "./home-text-section.module.css"
import cx from "classnames"
import Image, { FluidObject } from "gatsby-image"
import Link from "./link-button"
import H1 from "./h1"
import PaddedColumn from "./padded-column"
import { Maybe, MarkdownRemark } from "../generated/graphql"
import renderAst from "../lib/render-ast"
import { getLink } from "./menu"

const HomeTextSection: FunctionComponent<{
  image?: FluidObject
  title?: Maybe<string>
  textAst?: Pick<MarkdownRemark, "htmlAst">
  links?: Maybe<
    Maybe<{
      page?: {
        slug: string
        isHomepage: boolean
      }
      label?: Maybe<string>
    }>[]
  >
}> = ({ image, title, textAst = {}, links }) => {
  return (
    <section className={styles.section}>
      <div className={cx("grid", styles.grid)}>
        <PaddedColumn className={styles.content}>
          <H1 className={styles.title}>{title}</H1>
          {renderAst(textAst)}
          {links
            ? links.map((link, i) =>
                link ? (
                  <Link
                    key={i}
                    to={link.page ? getLink(link.page) : ""}
                    className={styles.cto}
                  >
                    {link.label}
                  </Link>
                ) : null
              )
            : null}
        </PaddedColumn>
        {image ? <Image className={styles.image} fluid={image} alt="" /> : null}
      </div>
    </section>
  )
}

export default HomeTextSection
