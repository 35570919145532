import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const HomeR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="513.1px"
        height="390.8px"
        viewBox="0 0 513.1 390.8"
      >
        >
        <GrainPattern id={id} y={"-20%"} />
        <path
          fill={`url(#${id})`}
          d="M0,0c0,0,24.3,19.1,36.1,34.4c6.8,8.9,14,16.4,15.5,27.9c1.4,10.4,1,22,0.9,32.5c-0.1,16.4,3.9,27.3,14.9,40.2 c14.5,17.1,39.4,26,59,36.1c22.3,11.6,42,25.9,67.7,28.4c27.6,2.7,54.3,5.7,81.7,10.8c25.4,4.7,51.8,6.4,76.8,12.6 c52.1,13,102.7,46.2,130.2,92.4c14,23.6,30.4,75.3,30.4,75.3l0-390.8L0,0z"
        />
        />
      </svg>
    )
  }
)

export default HomeR1
