import React, { FunctionComponent } from "react"
import styles from "./homepage-hero.module.css"
import cx from "classnames"
import Image, { FluidObject } from "gatsby-image"
import Link from "./link-button"
import H1 from "./h1"
import Splash from "./splash"
import {
  Maybe,
  GatsbyImageSharpFluidFragment,
  MarkdownRemark,
} from "../generated/graphql"
import { link } from "fs"
import renderAst from "../lib/render-ast"

const HomepageHero: FunctionComponent<{
  title?: Maybe<string>
  textAst?: Pick<MarkdownRemark, "htmlAst">
  image?: FluidObject
  link?: Maybe<string>
  linkLabel?: Maybe<string>
  ctoTitle?: Maybe<string>
  ctoSubtitle?: Maybe<string>
  ctoSubtitleLink?: Maybe<string>
}> = ({
  title,
  textAst,
  image,
  link,
  linkLabel,
  ctoTitle,
  ctoSubtitle,
  ctoSubtitleLink,
}) => {
  return (
    <div className={styles.hero}>
      <div className={cx("grid", styles.grid)}>
        <div className={styles.content}>
          <H1>{title}</H1>
          {renderAst(textAst)}
          {link && linkLabel ? (
            <Link to={link} className={styles.cto}>
              {linkLabel}
            </Link>
          ) : null}
        </div>
        <div className={styles.imageCol}>
          <Image fluid={image} loading="eager" className={styles.image} />
          <Splash rotate="counterClockwise" className={styles.splash}>
            <h4 className={styles.splashTitle}>{ctoTitle}</h4>
            <p className={styles.splashTel}>
              {ctoSubtitleLink ? (
                <a href={ctoSubtitleLink} className={styles.tel}>
                  {ctoSubtitle}
                </a>
              ) : (
                <span className={styles.tel}>{ctoSubtitle}</span>
              )}
            </p>
          </Splash>
        </div>
      </div>
    </div>
  )
}

export default HomepageHero
