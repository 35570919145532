import React, { FunctionComponent } from "react"
import styles from "./home-parallax.module.css"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import { useStaticQuery, graphql } from "gatsby"
import HomeL from "./blobs/home-l"
import HomeR1 from "./blobs/home-r-1"
import HomeR2 from "./blobs/home-r-2"

const HomeParallax: FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    {
      left: file(name: { regex: "/shape-home-top-left/" }) {
        publicURL
      }
      right: file(name: { regex: "/shape-home-top-right/" }) {
        publicURL
      }
    }
  `)
  const refLeft = useParallax<SVGSVGElement>(null, makeTransformY(0.5))
  const refRight = useParallax<SVGSVGElement>(null, makeTransformY(0.5))
  const refRight2 = useParallax<SVGSVGElement>(null, makeTransformY(3.4))

  return (
    <>
      <HomeL ref={refLeft} className={styles.tl} />
      <HomeR1 ref={refRight} className={styles.tr} />
      <HomeR2 ref={refRight2} className={styles.tr2} />
    </>
  )
}

export default HomeParallax
