import React, { FunctionComponent } from "react"
import styles from "./link-button.module.css"
import cx from "classnames"
import Link from "gatsby-link"

const LinkButton: FunctionComponent<{
  className?: string
  to: string
  center?: boolean
}> = ({ className, to = "", center = false, ...rest }) => {
  const props = {
    className: cx(styles.link, className, {
      [styles.center]: center,
    }),
    ...rest,
  }

  if (to.substr(0, 1) !== "/") {
    return <Link {...props} to={to} />
  }

  return <a {...props} rel="noopener noreferer" href={to} />
}

export default LinkButton
