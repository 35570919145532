import React, { FunctionComponent } from "react"
import styles from "./padded-column.module.css"
import cx from "classnames"

const PaddedColumn: FunctionComponent<{
  className?: string
}> = ({ className, ...rest }) => {
  const props = {
    className: cx(styles.paddedColumn, className),
    ...rest,
  }

  return <div {...props} />
}

export default PaddedColumn
