import React, { FunctionComponent, ReactNode, ReactElement } from "react"
import styles from "./splash.module.css"
import cx from "classnames"

export type SplashColor = "yellow" | "pink" | "orange" | "red" | "purple"

const Splash: FunctionComponent<{
  className?: string
  onClick?: (...args: any) => void
  rotate?: "counterClockwise" | "clockwise"
  color?: SplashColor
  inline?: boolean
  as?: (props: any) => ReactElement
}> = ({
  className,
  as: Component = props => <div {...props} />,
  rotate,
  inline,
  color = "",
  ...rest
}) => {
  const props = {
    className: cx(styles.splash, styles[color], className, {
      [styles.rCounterClockwise]: rotate === "counterClockwise",
      [styles.rClockwise]: rotate === "clockwise",
      [styles.inline]: inline,
    }),
    ...rest,
  }

  return <Component {...props} />
}

export const Title: FunctionComponent = ({ children }) => (
  <h3 className={styles.title}>{children}</h3>
)

export const Subtitle: FunctionComponent = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)

export default Splash
