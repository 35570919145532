import React, { FunctionComponent } from "react"
import styles from "./h1.module.css"
import cx from "classnames"

const H1: FunctionComponent<{
  className?: string
}> = ({ className, ...rest }) => {
  const props = {
    className: cx(styles.h1, className),
    ...rest,
  }

  return <h1 {...props} />
}

export default H1
