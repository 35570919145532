import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const HomeL = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="376.064"
        height="122.543"
        viewBox="0 0 376.064 122.543"
      >
        >
        <GrainPattern id={id} y={"-20%"} />
        <path
          fill={`url(#${id})`}
          d="M0,0V122.543c11.07-2.7,20.828-4.546,67.453-26.385,43.737-30.549,95.779-34.543,147.925-41.15,23.167-2.936,44.882-4.224,67.607-10.534C315.658,35.393,346.546,16.48,376.064,0,375.158,1.776,0,0,0,0Z"
        />
        />
      </svg>
    )
  }
)

export default HomeL
