import React, { FunctionComponent } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomepageHero from "../components/homepage-hero"
import ImageSection from "../components/image-section"
import HomeTextSection from "../components/home-text-section"
import HomeParallax from "../components/home-parallax"
import { graphql } from "gatsby"
import { HomeQuery } from "../generated/graphql"
import { FluidObject } from "gatsby-image"
import { oc } from "ts-optchain"

const IndexPage: FunctionComponent<{ data: HomeQuery }> = ({
  data: { contentfulHomePage },
}) => {
  if (!contentfulHomePage) {
    throw new Error("No homepage data returned from contentful")
  }

  const heroImage = oc(
    contentfulHomePage
  ).heroImage.localFile.childImageSharp.fluid() as FluidObject | undefined

  const imageSectionImage = oc(
    contentfulHomePage
  ).imageSectionBackground.localFile.childImageSharp.fluid() as
    | FluidObject
    | undefined

  const textAst = oc(
    contentfulHomePage
  ).imageSectionText.childMarkdownRemark.htmlAst()

  const splashTextAst = oc(
    contentfulHomePage
  ).imageSectionSplashText.childMarkdownRemark.htmlAst()

  const heroText = oc(contentfulHomePage).heroText.childMarkdownRemark.htmlAst()

  const thirdSectionTextAst = oc(
    contentfulHomePage
  ).thirdSectionText.childMarkdownRemark.htmlAst()

  const thirdSectionImage = oc(
    contentfulHomePage
  ).thirdSectionImage.localFile.childImageSharp.fluid() as
    | FluidObject
    | undefined

  return (
    <Layout toggleColor="purple">
      <SEO title="Yalla" />
      <HomeParallax />
      <HomepageHero
        title={contentfulHomePage.heroTitle}
        textAst={heroText}
        image={heroImage}
        link={contentfulHomePage.heroLink}
        linkLabel={contentfulHomePage.heroLinkLabel}
        ctoTitle={contentfulHomePage.heroCallToAction}
        ctoSubtitle={contentfulHomePage.heroCallToActionSubtitle}
        ctoSubtitleLink={contentfulHomePage.heroCallToActionSubtitleLink}
      />
      <ImageSection
        image={imageSectionImage}
        title={contentfulHomePage.imageSectionTitle}
        textAst={textAst}
        link={contentfulHomePage.imageSectionLink}
        linkLabel={contentfulHomePage.imageSectionLinkLabel}
        splashTextAst={splashTextAst}
        splashTitle={contentfulHomePage.imageSectionSplashTitle}
      />
      <HomeTextSection
        title={contentfulHomePage.thirdSectionTitle}
        textAst={thirdSectionTextAst}
        image={thirdSectionImage}
        links={contentfulHomePage.thirdSectionLinks}
      />
    </Layout>
  )
}

export const Home = graphql`
  query Home($id: String!) {
    contentfulHomePage(id: { eq: $id }) {
      heroTitle
      heroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      heroLink
      heroLinkLabel
      heroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 848) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      heroCallToAction
      heroCallToActionSubtitle
      heroCallToActionSubtitleLink
      imageSectionTitle
      imageSectionLink
      imageSectionText {
        childMarkdownRemark {
          htmlAst
        }
      }
      imageSectionLinkLabel
      imageSectionSplashTitle
      imageSectionSplashText {
        childMarkdownRemark {
          htmlAst
        }
      }
      imageSectionBackground {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      thirdSectionTitle
      thirdSectionText {
        childMarkdownRemark {
          htmlAst
        }
      }
      thirdSectionLinks {
        page {
          slug
          isHomepage
        }
        label
      }
      thirdSectionImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 565) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
