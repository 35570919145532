import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const HomeL = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="602.977"
        height="334.376"
        viewBox="0 0 602.977 334.376"
      >
        >
        <GrainPattern id={id} y={"-20%"} />
        <path
          fill={`url(#${id})`}
          d="M602.934.124S.286-.2,0,.193C4.524,25,10.287,43.535,18.194,50.1,48.029,74.878,205.247,212.035,287.677,220.059s176.378,23.008,235.207,51.353c30.121,14.512,43.93,38.858,80.093,62.964C602.551,332.3,602.934.124,602.934.124Z"
        />
        />
      </svg>
    )
  }
)

export default HomeL
