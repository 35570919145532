import React, { FunctionComponent } from "react"
import styles from "./image-section.module.css"
import cx from "classnames"
import Image, { FluidObject } from "gatsby-image"
import Link from "./link-button"
import H1 from "./h1"
import PaddedColumn from "./padded-column"
import Splash from "./splash"
import { Maybe, MarkdownRemark } from "../generated/graphql"
import renderAst from "../lib/render-ast"

const ImageSection: FunctionComponent<{
  image?: FluidObject
  title?: Maybe<string>
  textAst?: Pick<MarkdownRemark, "htmlAst">
  linkLabel?: Maybe<string>
  link?: Maybe<string>
  splashTitle?: Maybe<string>
  splashTextAst?: Pick<MarkdownRemark, "htmlAst">
}> = ({ image, title, textAst = {}, link, linkLabel }) => {
  return (
    <section className={styles.section}>
      <div className={cx("grid", styles.grid)}>
        <PaddedColumn className={styles.content}>
          <H1 className={styles.title}>{title}</H1>
          <div className={styles.withBackground}>
            {renderAst(textAst)}
            {link && linkLabel ? (
              <Link to={link} className={styles.cto}>
                {linkLabel}
              </Link>
            ) : null}
          </div>
        </PaddedColumn>
      </div>
      <Image fluid={image} className={styles.backgroundImage} alt="" />
    </section>
  )
}

export default ImageSection
